import { RichText } from "../../internal-components/richText/RichText.js";

interface Props {
  text?: string;
}

const Text: React.FC<Props> = ({ text }) => {
  if (!text) return null;

  return (
    <div className="text mw-small vs-xl hs">
      <RichText html={text} />
    </div>
  );
};

export default Text;
